import API from '../api'

export default function() {
  return [
    // Media hero and content
    {
      component: 'Flex',
      props: {
        gap: '0',
      },
      blocks: {
        default: [
          {
            component: 'Hero',
            props: {
              images: this.$path('item.media.cover'),
            },
          },

          {
            component: 'Text',
            data: {
              content: `<div class="hero-wrap__content"><img src="${
                API.logoInverted
              }" alt="Logo" class="hero-wrap__content-logo"/>${this.$path(
                'item.description',
              )}</div>`,
            },
            props: {
              textAlign: 'center',
              class: 'hero-wrap',
            },
          },
        ],
      },
    },

    // First text block
    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'project-page__first-text',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'section__text',
            },
            data: {
              content: this.$path('item.customFields.quote'),
            },
          },
        ],
      },
    },

    // Vimeo section
    {
      condition: this.$path('item.customFields.media.url'),
      component: 'Section',
      props: {
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Media',
            data: this.$path('item.customFields.media'),
          },
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.media.description'),
            },
          },
        ],
      },
    },

    // Second text block
    {
      component: 'Section',
      props: {
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'section__text',
            },
            data: {
              content: this.$path('item.customFields.about-1'),
            },
          },
        ],
      },
    },

    // Additonal info columns
    {
      component: 'Section',
      props: {
        class: 'project-page__additional-info',
        width: 'small',
      },
      blocks: {
        default: {
          component: 'Grid',
          blocks: {
            default: (this.$path('item.customFields.section') || []).map((item, index) => {
              return {
                component: 'Flex',
                blocks: {
                  default: [
                    {
                      component: 'Image',
                      data: {
                        image: item,
                      },
                      props: {
                        class: 'project-page__additional-info-image',
                      },
                    },
                  ],
                },
              }
            }),
          },
        },
      },
    },

    // Third text block
    {
      component: 'Section',
      props: {
        class: 'project-page__textblock',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'section__text',
            },
            data: {
              content: this.$path('item.customFields.bottom-info'),
            },
          },
        ],
      },
    },

    // References logo text block
    {
      component: 'Flex',
      props: {
        justifyContent: 'center',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'project-page__references-text',
            },
            data: {
              content: this.$path('item.customFields.references.description'),
            },
          },
        ],
      },
    },

    // Logo section bottom
    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'project-page__logos',
      },
      blocks: {
        default: {
          component: 'Flex',
          props: {
            flexWrap: 'wrap',
            justifyContent: 'center',
          },
          blocks: {
            default: (this.$path('item.customFields.references.logos') || []).map(item => {
              return {
                component: 'Link',
                data: {
                  href: item.description,
                },
                blocks: {
                  default: {
                    component: 'Image',
                    data: {
                      image: item,
                    },
                  },
                },
              }
              // return {
              //   component: 'Image',
              //   data: {
              //     image: item,
              //   },
              //   props: {
              //     class: '',
              //   },
              // }
            }),
          },
        },
      },
    },

    // Call to action bottom
    {
      component: 'Flex',
      props: {},
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'section__text',
            },
            data: {
              content: this.$path('item.customFields.call-to-action'),
            },
          },
        ],
      },
    },
  ]
}

<template>
  <div
    id="app"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="20px 0"
          :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <transition mode="out-in" name="route">
      <router-view />
    </transition>
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from './gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },

    defaultItems() {
      return [
        ...this.posts.map(i => {
          if (!this.$path('customFields.show-in-navigation', i)) return {}

          return {
            label: i.title,
            exact: true,
            action: this.redirect(['Post'], {
              params: { slug: i.slug },
              forceRedirect: true,
            }),
            order: this.$path('customFields.navigation-order', i) * 10 - 5,
          }
        }),
        {
          label: this.$t('leadFormTitle'),
          class: 'elder__navigation-component--primary',
          action: this.redirect('Contact'),
          order: 100,
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), {
      passive: true,
    })
    storeScroll()

    this.promise = this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--primary);
  color: #f0e8d4;
}

.kvass-media-render-vimeo__blocked-warning {
  color: var(--primary);
}

.nav {
  .container {
    max-width: initial;
  }
  position: fixed;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: var(--secondary);

  .elder__navigation {
    padding-block: 0.25rem !important;

    &-actions {
      flex-grow: 1;
    }

    &-node:last-child {
      margin-left: auto;
    }

    &-logo {
      flex-grow: 1;
    }

    &-bars {
      color: var(--primary);
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    &-component {
      color: var(--primary);
      font-weight: 400;
      cursor: pointer;
      border-radius: 0px;
      font-size: 16px;
      padding: 8px 16px 8px 15px;
      // margin-bottom: 0.5rem;

      &--primary {
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color: white;
        font-weight: 500;
      }

      &--primary:hover {
        background-color: white;
        color: var(--secondary);
      }
    }

    &-logo-image {
      max-height: 50px !important;
      cursor: pointer;
    }
  }

  &--project-expanded,
  &--custom-expanded,
  &--contact-expanded,
  &--plot-expanded,
  &--post-expanded {
    padding-bottom: 1rem;
    background: var(--secondary);
    height: 100%;
    display: flex;

    & > .container {
      display: flex;
    }

    .elder__navigation {
      height: 100%;

      &-node:last-child {
        margin-left: 0;
      }

      &-logo-image {
        display: none;
      }

      &-bars {
        color: var(--primary);
      }
    }

    .elder__navigation-actions {
      height: 85vh;
      display: flex !important;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;

      .elder__navigation-component {
        padding: 0.8rem 2.5rem;
        font-size: 2rem;
        font-weight: 500;

        &--primary:hover {
          background-color: var(--secondary);
        }
      }
    }
  }

  @include respond-below('phone') {
    position: fixed;
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>

<template>
  <footer class="footer" :class="`footer--theme-${theme}`">
    <div class="footer__content">
      <!-- <FooterContent
        v-if="customFooterContent.length"
        :value="customFooterContent"
        class="footer__custom-content container"
      /> -->
      <div :class="{ container: theme == 'compact' }">
        <div :class="{ container: theme == 'default' }">
          <h2>Interessert i eiendommen?</h2>
          <a href="/meld-interesse">
            <ButtonComponent class="footer__content-button">Meld interesse</ButtonComponent>
          </a>
          <hr />
          <div class="footer__content-some">
            <div v-for="item in customFooterButtons" :key="item.title">
              <a :href="item.url" target="_blank">
                <ButtonComponent class="footer__content-button footer__content-outlined">
                  <img :src="item.icon.url" height="20px" />{{ item.title }}
                </ButtonComponent>
              </a>
            </div>
          </div>

          <div class="footer__content-bottom">
            <a
              class="footer__bottom-link footer__bottom-link-left"
              href="https://malling.no/forbehold"
              >Disclaimer</a
            >
            <router-link
              class="footer__bottom-link"
              :to="`/api/legal/privacy/project/${item.id}`"
              target="_blank"
            >
              {{ $t('privacyPolicy') }}
            </router-link>

            <span class="footer__bottom-text">
              Dette digitale prospektet er utviklet i samarbeid mellom
              <span class="footer__bottom-bold">Malling & Co Markets</span> og
              <span class="footer__bottom-bold">utviklerne</span>.
            </span>
            <div class="footer__bottom-line">
              <span>
                {{ $t('pageMadeBy') }}
                <a :href="`https://kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
              </span>
              <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact'].includes(value)
      },
    },
    cookieManager: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    customFooterButtons() {
      if (!this.item) return
      return GetCustomField('footer-buttons', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      //return logo based on primary-color lightness

      if (!this.item.customFields) return

      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (this.item.customFields['footer-logo']) return this.item.customFields['footer-logo'].url
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted

      return API.logo
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },

  components: {
    ActionComponent,
    FooterContent,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--secondary);
  font-size: 0.92rem;
  margin-top: 4rem;

  &__content-some {
    display: flex;
    flex-wrap: wrap;
  }

  &__clickable {
    font-size: 0.875rem;

    & > button:hover,
    & > a:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    max-width: 160px;
    height: 100%;
    max-height: 60px;
  }

  &__content {
    padding: 4rem 2rem 2rem 2rem;

    text-align: center;
    color: var(--primary);

    @include respond-below('phone') {
      padding: 2rem 1rem 1rem 1rem;
    }

    hr {
      border-top: 1px solid black;
      margin: 3rem 0;
    }
    h2 {
      font-weight: 300;
    }

    &-button {
      padding: 0.65rem 1.2rem;
      border-radius: 0 !important;
      background-color: var(--primary);
      border: 2px solid var(--primary);
      color: white;
      font-weight: 600;
      font-size: 0.85rem;
      img {
        margin-right: 8px;
      }
    }
    &-button:hover {
      background-color: var(--secondary);
      color: var(--primary);
    }
    &-outlined {
      background-color: transparent;
      color: var(--primary);
    }
    &-outlined:hover {
      background-color: var(--primary);
      color: white;
    }
    &-some {
      display: flex;
      gap: 1rem;
      justify-content: center;
      margin-bottom: 5rem;
    }
  }

  &__bottom {
    padding-block: 0.5rem;
    padding-top: 3rem;

    & > div {
      display: flex;
      justify-content: space-between;

      gap: 1rem;
      flex-wrap: wrap;
    }
    &-text {
      text-align: justify;
      font-weight: 300;

      @include respond-below('phone') {
        display: block;

        margin-top: 1rem;
      }
    }
    &-link {
      text-transform: uppercase;
      font-weight: 600;
    }
    &-link-left {
      border-right: 1px solid;
      padding-right: 5px;
    }
    &-bold {
      font-weight: 600;
    }
    &-line {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      gap: 0 1rem;
      flex-wrap: wrap;

      a {
        font-weight: 600;
      }

      @include respond-below('phone') {
        justify-content: start;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }

  &__copyright {
    a {
      font-weight: bold;
    }
  }

  &__policy {
    display: flex;
    flex-wrap: wrap;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &-action {
      cursor: pointer;
      text-transform: uppercase;

      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;
  }

  &__links {
    display: flex;
    flex-direction: row;
    @include respond-below('phone') {
      align-items: center;
      justify-content: center;
    }

    gap: 1rem;
  }

  /* Themes */
  &--theme {
    &-compact {
      .footer__content {
        padding-block: 3rem;
      }

      .footer__bottom {
        padding-block: 1rem;
      }
    }
  }
}
</style>

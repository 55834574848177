<template>
  <Loader :value="promises.fetch" theme="default" class="project-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BrowserApiMixin from '@/mixins/browser-api'
import { Blocks } from '@kvass/pagebuilder'
import ScrollAnchor from '@kvass/scroll-anchor'
import ProjectComponents from '@/components/Project'

import Hero from '@/components/Hero'

import config from '@/config'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return { Hero, ScrollAnchor, ...ProjectComponents }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    ScrollAnchor,
    Hero,
    Blocks,
  },
}
</script>

<style lang="scss">
.project-page {
  .kpb-image__image {
    background-color: transparent;
  }

  &__references-text {
    text-align: center;
  }

  &__textblock {
    --section-padding-block: 2rem 2rem;
  }

  &__first-text {
    --section-padding-block: 4rem 2rem;
  }

  &__logos {
    --section-padding-block: 1rem 4rem;
    .kpb-image__caption {
      display: none;
    }

    @include respond-below('phone') {
      .kpb-image__image {
        height: 12rem;
      }
    }
  }

  @include respond-below('phone') {
    .kpb-image {
      margin-bottom: 1rem;
    }
  }

  a[data-cta] {
    border-radius: 0px;
    background-color: $beige;
    border: 2px solid $beige;
    color: var(--primary);
    font-weight: 500;
    font-size: 0.95rem;
    min-width: 150px;
    margin-top: 0.85rem;
    transition: 0.2s;
  }
  a[data-cta='primary']:hover {
    background-color: var(--primary);
    color: $beige;
    border: 2px solid $beige;
  }

  &__additional-info {
    --section-padding-block: 4rem 4rem;
  }

  .hero-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;

      @include respond-below('phone') {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 2rem;
      }

      h1 {
        font-size: 2.2rem;
        font-weight: 400;
      }

      &-logo {
        max-width: 20rem;
        @include respond-below('phone') {
          max-width: 14rem;
        }
      }
      @include respond-below('tablet') {
        h1 {
          font-size: 1.5rem;
        }
        p {
          font-size: 1rem;
        }
        a {
          margin-right: 2rem;
          margin-left: 2rem;
        }
      }
      @include respond-below('phone') {
        h1 {
          font-size: 1.2rem;
        }
        p {
          font-size: 0.95rem;
        }
        a {
          margin-right: 2rem;
          margin-left: 2rem;
        }
      }
    }
  }
  .section__text {
    margin: 0px auto;
    max-width: 30rem;
    font-weight: 300;
    strong {
      font-weight: 600;
    }
  }
}
</style>
